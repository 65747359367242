<template>
  <div>
    <v-row no-gutters class="fluid banner-icons">
      <v-col cols="12">
          <v-img
            src="/images/banner_icons.png"
            alt="GHG Calculator for Municipal Projects Excel Add-In"
            title="GHG Calculator Excel Add-In"
          ></v-img>
      </v-col>
    </v-row>
    <v-container class="d-flex align-center flex-column">
      <v-row justify="center">
        <v-col cols="auto">
          <v-img
            src="/images/excel-project-example.png"
            alt="GHG Calculator for Municipal Projects Excel Add-In"
            width="550"
            title="GHG Calculator Excel Add-In"
          ></v-img>
        </v-col>
        <v-col lg="6" xl="4">
          <p class="mt-4">
            The GHG Calculator for Municipal Projects is a free tool for municipal
            staff across all departments who want to understand the climate
            impact of their decisions. You can use the tool, which is an add-in
            for Microsoft Excel, to ensure your municipal budget and capital
            plan reflect your municipality’s commitment to climate action.
          </p>
          <p class="mt-4">
            This tool was designed by SSG together with the City of Calgary,
            Region of Durham, and City of Ottawa so that they could quickly
            understand the greenhouse gas implications of policies and
            decisions, such as instituting a composting program or adopting a
            policy to reduce greenfield development.
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col>
          <v-card class="text-center h-100">
            <v-card-title class="mt-4">Get the GHG Calculator</v-card-title>
            <v-card-text>
              <GhgCalculatorIconTagline class="mx-8"></GhgCalculatorIconTagline>
              <p class="text-center">
                The GHG Calculator can be downloaded for free from Microsoft
                AppSource.
              </p>
              <v-btn
                color="success"
                class="mt-4 mb-8"
                href="https://appsource.microsoft.com/en-us/product/office/WA200004453"
                target="_blank"
                >Get it now</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="text-center h-100" color="accent">
            <v-card-title class="mt-4"
              >Customize the GHG Calculator</v-card-title
            >
            <v-card-text>
              <GhgCalculatorCustomizeIcon class="mx-8"></GhgCalculatorCustomizeIcon>
              <p class="text-center">
                SSG can customize the GHG Calculator with your region or
                city-specific data.
              </p>
              <v-btn
                color="success"
                class="mt-4 mb-8"
                :to="localeRoute({ name: 'customization' })"
                >Learn More</v-btn
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-12">
        <v-col>
          <v-card class="h-100">
            <v-card-title>About the GHG Calculator</v-card-title>
            <v-card-text>
              <LiteYouTubeEmbed
                id="KQa0w90Cjd4"
                title="About the GHG Calculator"
              ></LiteYouTubeEmbed>
              <p class="mt-2">
                A brief introduction to the GHG Calculator, its intended
                purpose, and some of its features.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-16">
        <v-col cols="12">
          <h3 class="text-center">The GHG Calculator is supported by:</h3>
        </v-col>
        <v-col cols="4">
          <v-img
            src="/images/cityofcalgary-logo.png"
            alt="City of Calgary"
            title="City of Calgary"
            width="250"
            class="mx-auto"
          ></v-img>
        </v-col>
        <v-col cols="4">
          <v-img
            src="/images/durham-logo.png"
            alt="Durham Region"
            title="Durham Region"
            height="200"
            class="mx-auto"
          ></v-img>
        </v-col>
        <v-col cols="4">
          <v-img
            src="/images/city-of-ottawa-logo.png"
            alt="City of Ottawa"
            title="City of Ottawa"
            width="250"
            class="mx-auto"
          ></v-img>
        </v-col>
      </v-row>
      <v-row class="mt-16">
        <v-col cols="12">
          <h3 class="text-center">Developed and maintained by:</h3>
        </v-col>
        <v-col>
          <NuxtLink
            to="https://ssg.coop"
            target="_blank"
            title="Sustainability Solutions Group"
          >
            <v-img
              src="/images/ssg-logo.png"
              alt="Sustainability Solutions Group"
              title="Sustainability Solutions Group"
              width="270"
              class="mx-auto"
            ></v-img>
          </NuxtLink>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script lang="ts" setup>
import LiteYouTubeEmbed from "vue-lite-youtube-embed";
import "vue-lite-youtube-embed/style.css";

// svg logo icons
import GhgCalculatorIconTagline from "@/assets/images/ghg_calculator_icon_w_tagline.svg?component";
import GhgCalculatorCustomizeIcon from "@/assets/images/ghg_calculator_customize_icon.svg?component";

const localeRoute = useLocaleRoute();
useSeoMeta({
  description:
    "Use the GHG Calculator to ensure that your municipal budget and capital plan reflect your commitment to climate action.",
  ogTitle: "Home - GHG Calculator for Municipal Projects",
  ogDescription:
    "Use the GHG Calculator to ensure that your municipal budget and capital plan reflect your commitment to climate action.",
  ogImage: "/images/ghg_calculator_icon_250.png",
  ogUrl: "https://ghg-calculator.ssg.coop",
  twitterTitle: "Home - GHG Calculator for Municipal Projects",
  twitterDescription:
    "Use the GHG Calculator to ensure that your municipal budget and capital plan reflect your commitment to climate action.",
  twitterImage: "/images/ghg_calculator_icon_250.png",
  twitterCard: "summary",
});

useHead({
  title: "Home",
  htmlAttrs: {
    lang: "en",
  },
});
</script>
<style lang="scss">
@use "./settings";
</style>
<style lang="scss" scoped>
.banner-icons {
  background-color: #b6489b;
  margin: -55px 0 40px 0;
  padding: 20px;
  top: 0px;
}
</style>
